.popup {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.7);
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    .popup-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 500px;
        width: 100%;
        min-height: 300px;
        background-color: #ffd62b;
        // border: 10px solid #e2171e;
        padding: 20px 30px;
        text-align: center;
        &::after {
            content: "";
            position: absolute;
            top: -29px;
            right: 0;
            width: 0;
            height: 0;
            border-left: 29px solid transparent;
            border-right: 0px solid transparent;
            border-bottom: 29px solid #ffd62b;
        }
        @media( max-width: 600px ) {
            max-width: 330px;
        }
        h2 {
            color: #e2171e;
        }
        a {
            position: absolute;
            top: 10px;
            right: 10px;
            color: #e2171e;
            cursor: pointer;
            font-size: 28px;
            font-weight: bold;
        }
    }
}