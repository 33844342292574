.welcome {
    padding: 0 50px;
    // max-height: 100vh;
    overflow: hidden;
    @media( max-width: 1200px ) {
        padding: 0 30px;
    }
    @media( max-width: 500px ) {
        padding: 0 18px;
    }
    header {
        display: flex;
        justify-content: space-between;
        padding-top: 50px;
        @media( max-width: 1200px ) {
            padding-top: 30px;
        }
        @media( max-width: 500px ) {
            padding-top: 14px;
        }
        .logo-wrapper {
            img {
                width: 240px;
                height: 130px;
                @media( max-width: 1255px ) {
                    width: 200px;
                    height: 108px;
                }
                @media( max-width: 500px ) {
                    width: 109px;
                    height: 57px;
                }
            }
        }
        .icons-wrapper {
            img {
                @media( max-width: 1255px ) {
                    width: 187px;
                    height: 33px;
                }
                @media( max-width: 500px ) {
                    width: 96px;
                    height: 17px;
                }
            }
        }
    }
    main {
        display: flex;
        align-items: center;
        flex-direction: column;
        .start-wrapper {
            display: flex;
            justify-content: center;
            // max-width: 1630px;
            width: 100%;
            margin-top: -33px;
            @media( max-width: 1765px ) {
                margin-top: -20px;
            }
            @media( max-width: 1505px ) {
                margin-top: -15px;
            }
            @media( max-width: 1255px ) {
                margin-top: -20px;
            }
            @media( max-width: 1080px ) {
                flex-direction: column;
                align-items: center;
                margin-top: 60px;
            }
            @media( max-width: 870px ) {
                margin-top: 30px;
            }
            @media( max-width: 500px ) {
                margin-top: 25px;
            }
            h1 {
                transform: rotate(-6deg);
                z-index: 1;
                p {
                    font-size: 118.48px;
                    line-height: 127.32px;
                    color: #ce0000;
                    font-weight: 900;
                    font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                    text-align: right;
                    font-style: italic;
                    text-transform: uppercase;
                    margin: 0;
                    position: relative;
                    letter-spacing: -3px;
                    @media( max-width: 1765px ) {
                        font-size: 100px;
                        line-height: 105px;
                    }
                    @media( max-width: 1505px ) {
                        font-size: 90px;
                        line-height: 95px;
                    }
                    @media( max-width: 1380px ) {
                        font-size: 80px;
                        line-height: 85px;
                    }
                    @media( max-width: 1255px ) {
                        font-size: 76.61px;
                        line-height: 80.01px;
                    }
                    @media( max-width: 1135px ) {
                        font-size: 65px;
                        line-height: 70px;
                    }
                    @media( max-width: 1080px ) {
                        text-align: center;
                        font-size: 114.31px;
                        line-height: 122.84px;
                        left: -15px;
                    }
                    @media( max-width: 992px ) {
                        font-size: 100px;
                        line-height: 110px;
                    }
                    @media( max-width: 870px ) {
                        font-size: 80px;
                        line-height: 1;
                    }
                    @media( max-width: 700px ) {
                        font-size: 65px;
                    }
                    @media( max-width: 580px ) {
                        font-size: 55px;
                    }
                    @media( max-width: 500px ) {
                        font-size: 42.33px;
                        line-height: 45.49px;
                        left: -7px;
                    }
                    @media( max-width: 370px ) {
                        font-size: 40px;
                    }
                    &:first-of-type {
                        left: 80px;
                        @media( max-width: 1255px ) {
                            left: 60px;
                        }
                        @media( max-width: 1080px ) {
                            left: -15px;
                        }
                        @media( max-width: 600px ) {
                            left: -7px;
                        }
                    }
                }
            }
            .date-wrapper {
                margin-top: -50px;
                position: relative;
                @media( max-width: 1255px ) {
                    margin-top: -30px;
                }
                @media( max-width: 1080px ) {
                    margin-top: 0;
                }
                h2 {
                    position: absolute;
                    top: 90px;
                    left: 110px;
                    font-size: 70.68px;
                    line-height: 66.16px;
                    color: #ce0000;
                    font-weight: 900;
                    font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                    transform: rotate(-6deg);
                    @media( max-width: 1765px ) {
                        font-size: 60px;
                        line-height: 1;
                        top: 80px;
                        left: 100px;
                    }
                    @media( max-width: 1505px ) {
                        font-size: 50px;
                    }
                    @media( max-width: 1380px ) {
                        font-size: 45.7px;
                        top: 70px;
                        left: 110px;
                    }
                    @media( max-width: 1255px ) {
                        top: 60px;
                        left: 80px;
                    }
                    @media( max-width: 1080px ) {
                        font-size: 69.03px;
                        top: 85px;
                        left: 110px;
                    }
                    @media( max-width: 992px ) {
                        font-size: 60px;
                        top: 75px;
                        left: 100px;
                    }
                    @media( max-width: 870px ) {
                        font-size: 50px;
                        top: 65px;
                        left: 100px;
                    }
                    @media( max-width: 700px ) {
                        font-size: 45px;
                        top: 60px;
                        left: 70px;
                    }
                    @media( max-width: 580px ) {
                        font-size: 40px;
                        top: 50px;
                        left: 65px;
                    }
                    @media( max-width: 500px ) {
                        font-size: 25.56px;
                        top: 33px;
                        left: 45px;
                    }
                }
                img {
                    z-index: 10;
                    @media( max-width: 1765px ) {
                        width: 600px;
                        height: 250px;
                    }
                    @media( max-width: 1505px ) {
                        width: 550px;
                        height: 230px;
                        margin-left: 10px;
                    }
                    @media( max-width: 1380px ) {
                        width: 500px;
                        height: 210px;
                        margin-left: 15px;
                    }
                    @media( max-width: 1255px ) {
                        width: 465px;
                        height: 190px;
                        margin-left: 0;
                    }
                    @media( max-width: 1080px ) {
                        width: 700px;
                        height: 275px;
                    }
                    @media( max-width: 992px ) {
                        width: 600px;
                        height: 250px;
                    }
                    @media( max-width: 870px ) {
                        width: 550px;
                        height: 200px;
                    }
                    @media( max-width: 700px ) {
                        width: 450px;
                        height: 180px;
                    }
                    @media( max-width: 580px ) {
                        width: 400px;
                        height: 160px;
                    }
                    @media( max-width: 500px ) {
                        width: 260px;
                        height: 105px;
                    }
                }
            }
        }
        .image-bg-wrapper {
            display: flex;
            flex-direction: column;
            margin-top: 100px;
            position: relative;
            width: 100%;
            @media( max-width: 1255px ) {
                margin-top: 115px;
            }
            @media( max-width: 1080px ) {
                // margin-top: 340px;
                margin-top: 225px;
            }
            @media( max-width: 700px ) {
                margin-top: 180px;
            }
            @media( max-width: 580px ) {
                margin-top: 140px;
            }
            @media( max-width: 500px ) {
                margin-top: 70px;
            }
            img {
                position: relative;
                margin-top: -165px;
                left: 50%;
                transform: translateX(-50%);
                width: fit-content;
                height: fit-content;
                animation: animationWelcome 2s ease 2s 1 normal forwards;
                @media( max-width: 1765px ) {
                    width: 1000px;
                    height: auto;
                    margin-top: -120px;
                }
                @media( max-width: 1380px ) {
                    width: 900px;
                }
                @media( max-width: 1200px ) {
                    width: fit-content;
                    height: fit-content;
                    margin-top: -150px;
                }
                @media( max-width: 1080px ) {
                    width: fit-content;
                    height: fit-content;
                    // margin-top: -430px;
                    margin-top: -315px;
                }
                @media( max-width: 992px ) {
                    width: 800px;
                    height: auto;
                }
                @media( max-width: 870px ) {
                    width: 750px;
                    margin-top: -300px;
                }
                @media( max-width: 870px ) {
                    width: 650px;
                    margin-top: -250px;
                }
                @media( max-width: 580px ) {
                    width: 550px;
                    margin-top: -200px;
                }
                @media( max-width: 500px ) {
                    width: 420px;
                    margin-top: -100px;
                }
                @media( max-width: 400px ) {
                    width: 360px;
                    margin-top: -100px;
                }
                @keyframes animationWelcome {
                    10% {
                        transform: translateX(-50%) rotate(0);
                    }
                    40% {
                      transform: translateX(-50%) rotate(-10deg);
                    }
                    80% {
                        transform: translateX(-50%) rotate(10deg);
                    }
                    100% {
                        transform: translateX(-50%) rotate(0);
                    }
                }
            }
            .bg {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                span {
                    font-weight: 900;
                    font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                    font-size: 269.36px;
                    line-height: .715;
                    color: #fecf22;
                    -webkit-text-stroke-width: 3px;
                    -webkit-text-stroke-color: #ce0000;
                    font-style: italic;
                    user-select: none;
                    letter-spacing: -3px;
                    transition: .3s;
                    @media( max-width: 1765px ) {
                        font-size: 230px;
                    }
                    @media( max-width: 1380px ) {
                        font-size: 200px;
                    }
                    @media( max-width: 1550px ) {
                        font-size: 183.2px;
                    }
                    @media( max-width: 1100px ) {
                        font-size: 186.02px;
                        position: relative;
                        left: -15px;
                    }
                    @media( max-width: 1040px ) {
                        font-size: 160px;
                    }
                    @media( max-width: 992px ) {
                        font-size: 140px;
                    }
                    @media( max-width: 870px ) {
                        font-size: 125px;
                    }
                    @media( max-width: 700px ) {
                        font-size: 100px;
                    }
                    @media( max-width: 580px ) {
                        font-size: 80px;
                    }
                    @media( max-width: 500px ) {
                        font-size: 68.89px;
                        left: -7px;
                    }
                    @media( max-width: 385px ) {
                        font-size: 60px;
                    }
                }
            }
        }
    }
}