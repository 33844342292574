.input-wrapper {
    max-width: 100%;
    width: 100%;
    position: relative;
    .custom-file-upload {
        border: 4px solid transparent;
        display: inline-block;
        cursor: pointer;
        color: #cdcdcd;
        font-style: italic;
        font-size: 22.68px;
        background: #fff;
        padding: 19px 35px 14px 35px;
        width: 100%;
        @media( max-width: 1200px ) {
            font-size: 16.6px;
            padding: 17px 25px 13px 25px;
        }
    }
    .add {
        position: absolute;
        // top: 50%;
        top: 34px;
        right: 23px;
        transform: translateY(-50%);
        font-size: 36px;
        color: #e2171e;
        cursor: pointer;
        @media( max-width: 1200px ) {
            top: 28px;
        }
    }
    .info {
        display: flex;
        margin-top: 5px;
        p {
            color: #034992;
            font-size: 12px;
            &:last-of-type {
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }
    input {
        max-width: 100%;
        width: 100%;
        font-size: 22.68px;
        color: #e2171e;
        padding: 19px 35px 14px 35px;
        font-style: italic;
        outline: none;
        border: none;
        border: 4px solid transparent;
        background: #fff;
        @media( max-width: 1200px ) {
            font-size: 16.6px;
            padding: 17px 25px 13px 25px;
            &::placeholder {
                font-size: 16.6px!important;
            }
        }
        &::placeholder {
            color: #cdcdcd;
            font-style: italic;
            font-size: 22.68px;
        }
    }
    input[type="file"] {
        display: none;
    }
    &.error {
        .required {
            // &:before {
            //     top: 21px;
            //     left: 20px;
            // }
        }
        input, label {
            border-color: #e2171e;
        }
    }
    .required {
        &:before {
            content: "*";
            position: absolute;
            top: 14px;
            left: 11px;
            color: #e2171e;
            font-size: 30px;
        }
    }
}

.select-wrapper {
    max-width: 100%;
    width: 100%;
    // height: 53px; 
    min-height: 68px;
    position: relative;
    max-height: 68px;
    @media( max-width: 1200px ) {
        min-height: 58px;
        max-height: 58px;
    }
    &.error {
        .chosen {
            border: 1px solid #e2171e;
        }
        .options {
            
            &.active {
                border: 1px solid #e2171e;
                border-top: 0;
            }
        }
    }
    .chosen {
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        user-select: none;
        line-height: normal;
        background-color: #ffffff;
        padding: 19px 30px 14px 30px;
        color: #cdcdcd;
        font-style: italic;
        font-size: 21px;
        @media( max-width: 1400px ) {
            font-size: 18px;
        }
        @media( max-width: 1200px ) {
            font-size: 16.6px;
            padding: 17px 25px 13px 25px;
            &::placeholder {
                font-size: 16.6px!important;
            }
        }
        // @media( max-width: 1250px ) {
        //     font-size: 18px;
        // }
        &.active {
            
        }
        span {
            padding: 0 5px;
            &.selected {
                color: #e2171e;
            }
        }
        .dropdown-bird {
            position: absolute;
            top: calc(50% - 23px/2);
            right: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            @media( max-width: 1230px ) {
                right: 10px;
            }
            &.active {
    
                svg {
                    transform: rotate(-180deg);
                }
            }
            svg {
                width: 23px;
                height: 20px;
                transition: .3s;
            }               
        }
    
    }
    ::-webkit-scrollbar {
        width: 6px;
    }
    ::-webkit-scrollbar-track {
        background: #ffffff; 
    }
    ::-webkit-scrollbar-thumb {
        background: #e8e8e8; 
        border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #e8e8e8; 
    }
    ::-moz-selection {
        background: #e8e8e8; 
    }
      
    ::selection {
        color: #ffffff;
        background: gray; 
    }
    .options {
        display: none;
        position: absolute;
        max-width: 100%;
        width: 100%;
        user-select: none;
        z-index: 2;
        transition: .3s;
        max-height: 200px;
        overflow-y: auto;
        border-top: 2px solid #e8e8e8;
        &.active {
            display: block;
            padding: 11px 45px 12px 45px;
            background-color: #ffffff;
    
            
            p {
                cursor: pointer;
                transition: .4s;
                margin: 10px 0;
                color: #cdcdcd;
                font-style: italic;
                font-size: 18px;
            }
        }
    }
}