.rewards {
    padding-top: 190px;
    background-image: url('./../../images/bg.png');
    // background-position: top left;
    background-position: center;
    background-repeat: no-repeat;
    // background-size: 100% 100%;
    // background-size: cover 100%;
    background-attachment: scroll;
    min-width: 100vw;
    height: 2895px;
    @media( max-width: 1200px ) {
        background-image: url('./../../images/bg-tablet.png');
        height: 2277px;
        padding-top: 155px;
    }
    @media( max-width: 992px ) {
        padding-top: 78px;
        background-position-y: 75px;
    }
    @media( max-width: 500px ) {
        background-image: url('./../../images/bg-mobile-500px.png');
        height: 2352px;
        background-position-y: 30px;
    }
    @media( max-width: 450px ) {
        background-position-y: 0px;
    }
    @media( max-width: 400px ) {
        background-image: url('./../../images/bg-mobile.png');
        height: 2352px;
        background-position-y: -40px;
    }
    .container {
        display: flex;
        justify-content: center;
        position: relative;
        flex-direction: column;
        .row {
            width: 100%;
            &.banner {
                color: #de171d;
                text-transform: uppercase;
                font-style: italic;
                line-height: 1;
                .box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: relative;
                    text-align: center;
                    p {
                        font-weight: 900;
                        font-size: 64px;
                        line-height: 1;
                        font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                        @media( max-width: 1200px ) {
                            font-size: 56.49px;
                        }
                        @media( max-width: 992px ) {
                            margin-top: 25px;
                        }
                        @media( max-width: 500px ) {
                            font-size: 55px;
                        }
                        @media( max-width: 420px ) {
                            font-size: 52px;
                        }
                        @media( max-width: 390px ) {
                            font-size: 48px;
                        }
                        @media( max-width: 370px ) {
                            font-size: 46px;
                        }
                        &.question-mark {
                            font-size: 85px;
                            margin-bottom: 30px;
                            margin-top: -55px;
                            @media( max-width: 1200px ) {
                                font-size: 75px;
                                margin-top: -40px;
                                margin-bottom: 10px;
                            }
                            @media( max-width: 992px ) {
                                margin-top: -55px;
                            }
                            @media( max-width: 500px ) {
                                font-size: 71.6px;
                                margin-top: -40px;
                            }
                        }
                    }
                    img {
                        width: 534px;
                        height: 302px;
                        z-index: 2;
                        @media( max-width: 1200px ) {
                            width: 450px;
                            height: 250px;
                        }
                        @media( max-width: 500px ) {
                            // width: 340px;
                            // height: 190px;
                            z-index: 2;
                            max-width: 100%;
                            width: auto;
                            height: auto;
                        }
                        &.tablet {
                            display: none!important;
                            @media( max-width: 1200px ) {
                                display: block!important;
                            }
                            @media( max-width: 500px ) {
                                display: none!important;
                            }
                        }
                        
                        &.mobile {
                            display: none!important;
                            @media( max-width: 500px ) {
                                display: block!important;
                            }
                        }
                    }
                    h3 {
                        font-size: 28.35px;
                        font-weight: 900;
                        margin-bottom: 10px;
                        font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                        @media( max-width: 1200px ) {
                            font-size: 22.84px;
                            margin-bottom: 5px;
                        }
                        @media( max-width: 500px ) {
                            font-size: 26px;
                        }
                        @media( max-width: 400px ) {
                            font-size: 25px;
                        }
                        span {
                            font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                        }
                        @media( max-width: 450px ) {
                            span {
                                width: 100%;
                                display: block;
                            }
                        }
                    }
                    label {
                        font-size: 16.79px;
                        @media( max-width: 1200px ) {
                            font-size: 13.19px;
                        }
                        @media( max-width: 500px ) {
                            font-size: 14.6px;
                        }
                    }
                    .input-wrapper {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        height: 56px;
                        margin-top: 15px;
                        max-width: max-content;
                        @media( max-width: 1200px ) {
                            height: 50px!important;
                            margin-top: 10px!important;
                            input {
                                max-width: 290px!important;
                                padding: 13px 50px 14px 17px!important;
                                font-size: 29.2px!important;
                                height: 50px!important;
                                &::placeholder {
                                    font-size: 29.2px!important;
                                }
                            }
                            .icon-svg {
                                width: 33px!important;
                                height: 33px!important;
                                svg {
                                    path {
                                        width: 16px!important;
                                        height: 12px!important;
                                    }
                                }
                            }
                        }
                        input {
                            border: none;
                            max-width: 330px;
                            width: 100%;
                            height: 56px;
                            outline: none;
                            font-size: 32.78px;
                            font-weight: 900;
                            color: #de171d;
                            padding: 15px 55px 15px 20px;
                            font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                            @media( max-width: 400px ) {
                                max-width: 340px!important;
                                width: 100%;
                            }
                            &::placeholder {
                                font-size: 32.78px;
                                font-weight: 900;
                                color: #de171d;
                                font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                            }
                        }
                        .error-text {
                            margin-top: 5px;
                            color: #de171d;
                            font-size: 11px;
                            font-weight: bold;
                            max-width: 330px;
                            width: 100%;
                            @media( max-width: 400px ) {
                                max-width: 340px!important;
                            }
                        }
                        .icon-svg {
                            position: absolute;
                            top: 50%;
                            right: 13px;
                            transform: translateY(-50%);
                            width: 38px;
                            height: 38px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            background: #de171d;
                            animation: pullseInput 1s linear infinite;
                            cursor: pointer;
                            svg {
                                right: -1px;
                                position: relative;
                            }
                            @keyframes pullseInput {
                                0% {
                                    transform: translateY(-50%) scale(1);
                                }
                                50% {
                                  transform: translateY(-50%) scale(1.1);
                                } 
                                100% {
                                    transform: translateY(-50%) scale(1);
                                }
                            }
                        }
                    }
                    .arrow-icon {
                        margin-top: 40px;
                        @media( max-width: 1200px ) {
                            margin-top: 20px;
                            svg {
                                width: 22px;
                                height: 39px;
                            }
                        }
                    }
                    .pos3 {
                        position: absolute;
                        top: 25px;
                        left: 80px;
                        z-index: 100;
                        @media( max-width: 1350px ) {
                            left: 40px;
                        }
                        @media( max-width: 1240px ) {
                            left: 0;
                        }
                        @media( max-width: 1200px ) {
                            left: -35px;
                        }
                        @media( max-width: 1024px ) {
                            position: initial;
                            top: initial;
                            left: initial;
                            // margin-bottom: 30px;
                        }
                        .other-box {
                            // width: 260x;
                            // height: 140px;
                            width: 350px;
                            height: 140px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            @media( max-width: 1200px ) {
                                width: 320px;
                                height: 130px;
                            }
                            // @media( max-width: 1024px ) {
                            //     max-width: 620px;
                            //     width: 100%;
                            //     max-height: 245px;
                            //     height: 100%;
                            // }
                            img {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width: 350px;
                                height: 145px;
                                @media( max-width: 1200px ) {
                                    width: 320px;
                                    height: 130px;
                                }
                                // @media( max-width: 700px ) {
                                //     width: 620px;
                                //     height: 245px;
                                // }
                                // max-width: 100%;
                                // max-height: 100%;
                                &:last-of-type {
                                    width: 330px;
                                    height: 125px;
                                    @media( max-width: 1200px ) {
                                        width: 300px;
                                        height: 115px;
                                    }
                                    // @media( max-width: 700px ) {
                                    //     width: 600px;
                                    //     height: 225px;
                                    // }
                                }
                            }
                            h2 {
                                // position: absolute;
                                // top: 25px;
                                // left: 40px;
                                font-size: 18.48px;
                                line-height: 18.76px;
                                font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                                font-weight: 900;
                                // letter-spacing: -1px;
                                @media( max-width: 1200px ) {
                                    font-size: 17.18px;
                                    line-height: 16.06px;
                                }
                                .margin {
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }
                    .pos2 {
                        position: absolute;
                        top: -14px;
                        left: 210px;
                        @media( max-width: 1400px ) {
                            left: 150px;
                        }
                        @media( max-width: 1280px ) {
                            left: 100px;
                        }
                        @media( max-width: 1200px ) {
                            left: 70px;
                        }
                        @media( max-width: 992px ) {
                            display: none;
                        }
                        .other-box {
                            width: 206.5px;
                            height: 201px;
                            position: relative;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                            h2 {
                                position: absolute;
                                top: 36px;
                                left: 40px;
                                font-size: 19.7px;
                                line-height: 17.77px;
                                font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                                font-weight: 900;
                                letter-spacing: -1px;
                            }
                        }
                    }
                    .pos {
                        position: absolute;
                        top: 85px;
                        right: 10px;
                        @media( max-width: 1400px ) {
                            right: -51px;
                        }
                        @media( max-width: 1200px ) {
                            right: -126px;
                            top: 30px;
                        }
                        @media( max-width: 1190px ) {
                            right: -120px;
                        }
                        @media( max-width: 1170px ) {
                            right: -111px;
                        }
                        @media( max-width: 1150px ) {
                            right: -101px;
                        }
                        @media( max-width: 1130px ) {
                            right: -91px;
                        }
                        @media( max-width: 1110px ) {
                            right: -81px;
                        }
                        @media( max-width: 1090px ) {
                            right: -71px;
                        }
                        @media( max-width: 1070px ) {
                            right: -61px;
                        }
                        @media( max-width: 1050px ) {
                            right: -51px;
                        }
                        @media( max-width: 1030px ) {
                            right: -41px;
                        }
                        @media( max-width: 1010px ) {
                            right: -31px;
                        }
                        @media( max-width: 992px ) {
                            position: relative;
                            top: initial;
                            right: initial;
                        }
                        @media( max-width: 500px ) {
                            width: 100%;
                        }
                        .normal-box {
                            max-width: 380px;
                            padding: 20px 15px;
                            border: 1px solid #de171d;
                            text-align: center;
                            &.small-tablet {
                                display: none;
                                @media( max-width: 992px ) {
                                    display: block;
                                    border-top: none;
                                }
                            }
                            @media( max-width: 500px ) {
                                max-width: calc(100% + 62px);
                                width: calc(100% + 62px);
                                position: relative;
                                left: - 31px;
                            }
                            p {
                                font-size: 15.47px;
                                font-weight: 900;
                                margin-bottom: 5px;
                                @media( max-width: 1200px ) {
                                    font-size: 13.81px;
                                    margin: 0;

                                }
                            }
                            h2 {
                                font-size: 25.45px;
                                font-weight: 900;
                                @media( max-width: 1200px ) {
                                    font-size: 23.63px;
                                }
                                @media( max-width: 1080px ) {
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                    .avatar {
                        position: absolute;
                        .image-wrapper {
                            img {
                                width: 154px;
                                height: 163px;
                            }
                        }
                        .ww-box {
                            position: absolute;
                            img {

                            }
                        }
                        &.avatar-left {
                            left: 165px;
                            @media( max-width: 1350px ) {
                                left: 160px!important;
                                top: 35px!important;
                            }
                            @media( max-width: 1200px ) {
                                left: 85px!important;
                                top: 0px!important;
                                .image-wrapper {
                                    img {
                                        width: 104px!important;
                                        height: 109px!important;
                                    }
                                }
                                .ww-box {
                                    top: 50px!important;
                                    left: -135px!important;
                                    img {
                                        width: 170.284px!important; 
                                        height: 108.284px!important;
                                    }
                                }
                            }
                            @media( max-width: 1130px ) {
                                transform: scale(.8) !important;
                            }
                            @media( max-width: 992px ) {
                                display: none;
                            }
                            .image-wrapper {
                                img {
                                    width: 154px;
                                    height: 163px;
                                }
                            }
                            .ww-box {
                                top: 90px;
                                left: -195px;
                                img {
                                    width: 259px;
                                    height: 166px;
                                }
                            }
                        }
                        &.avatar-right {
                            right: 65px;
                            bottom: 65px;
                            @media( max-width: 1350px ) {
                                bottom: -65px!important;
                            }
                            @media( max-width: 1200px ) {
                                bottom: 125px!important;
                                right: 0px!important;
                                .image-wrapper {
                                    img {
                                        width: 223px!important;
                                        height: 223px!important;
                                    }
                                }
                                .ww-box {
                                    bottom: -95px!important;
                                    left: 85px!important;
                                    img {
                                        width: 213.284px!important;
                                        height: 153.284px!important;
                                    }
                                }
                            }
                            @media( max-width: 1130px ) {
                                bottom: 105px!important;
                                right: 30px!important;
                                transform: scale(.8) !important;
                            }
                            @media( max-width: 992px ) {
                                display: none;
                            }
                            .image-wrapper {
                                img {
                                    width: 281px;
                                    height: 281px;
                                }
                            }
                            .ww-box {
                                bottom: -120px;
                                left: 105px;
                                img {
                                    width: 274px;
                                    height: 198px;
                                }
                            }
                        }
                    }
                }
            }
            &.video {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-top: 200px;
                position: relative;
                height: 1940px;
                @media( max-width: 1200px ) {
                    height: auto;
                    margin-top: 100px;
                }
                @media( max-width: 820px ) {
                    margin-top: 210px;
                } 
                @media( max-width: 500px ) {
                    margin-top: 125px;
                }
                @media( max-width: 460px ) {
                    margin-top: 150px;
                }
                @media( max-width: 450px ) {
                    margin-top: 130px;
                }
                @media( max-width: 410px ) {
                    margin-top: 200px;
                }
                @media( max-width: 400px ) {
                    margin-top: 120px;
                }
                @media( max-width: 380px ) {
                    margin-top: 130px;
                }
                @media( max-width: 370px ) {
                    margin-top: 140px;
                }
                @media( max-width: 360px ) {
                    margin-top: 160px;
                }
                @media( max-width: 350px ) {
                    margin-top: 130px;
                }
                .video-wrapper {
                    max-width: 900px;
                    width: 100%;
                    height: 500px;
                    border: 16px solid #000; 
                    background-color: #000;
                    z-index: 2;
                    @media( max-width: 1200px ) {
                        max-width: 740px;
                        height: 420px;
                        border: 14px solid #000;
                    }
                    @media( max-width: 820px ) {
                        max-width: 600px;
                        height: 300px;
                    }
                    @media( max-width: 670px ) {
                        height: 230px;
                        border: 7px solid #000;
                    }
                    @media( max-width: 500px ) {
                        width: calc(100% + 60px);
                    }
                    @media( max-width: 400px ) {
                        width: calc(100% + 50px);
                    }
                    .poster {
                        position: relative;
                        cursor: pointer;
                        max-width: 100%;
                        max-height: 100%;
                        &:hover {
                            .play {
                                animation: pullse .5s linear infinite;
                                @keyframes pullse {
                                    0% {
                                        transform: translate(-50%, -50%) scale(1);
                                    }
                                    50% {
                                    transform: translate(-50%, -50%) scale(1.4);
                                    } 
                                    100% {
                                        transform: translate(-50%, -50%) scale(1);
                                    }
                                }
                            }
                        }
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            max-width: 100%;
                            max-height: 100%;
                            @media( max-width: 820px ) {
                                max-height: 272px;
                            }
                            @media( max-width: 670px ) {
                                height: 216px;
                            }
                        }
                        .play {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            @media( max-width: 1200px ) {
                                width: 52px;
                                height: 61px;
                                svg {
                                    width: 52px;
                                    height: 61px;
                                }
                            }
                            @media( max-width: 400px ) {
                                width: 29px;
                                height: 34px;
                                svg {
                                    width: 29px;
                                    height: 34px;
                                }
                            }
                        }
                    }
                    .video {
                        position: relative;
                        // padding-bottom: 56.25%;
                        padding-bottom: 54%;
                        height: 0;
                        iframe {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            // border-radius: 29px;
                            background: rgba(0, 0, 0, 0.40);
                            @media( max-width: 820px ) {
                                max-height: 272px;
                            }
                            @media( max-width: 670px ) {
                                height: 216px;
                            }
                        }
                    }
                }
                .pos {
                    z-index: 1;
                    .pos-item {
                        position: absolute;
                        top: 0;
                        @media( max-width: 1750px ) {
                            &.avatar-left {
                                top: 1550px!important;
                                left: 125px!important;
                            }
                            &.avatar-right {
                                right: -70px!important;
                            }
                        }
                        @media( max-width: 1520px ) {
                            &.avatar-left {
                                top: 550px!important;
                                left: 200px!important;
                            }
                            &.avatar-right {
                                transform: scale(.8);
                                top: 380px!important;
                                right: 200px!important;
                            }
                            &.four-right {
                                right: -30px!important;
                            }
                        }
                        @media( max-width: 1200px ) {
                            &.avatar-left {
                                top: 1250px!important;
                                left: 125px!important;
                            }
                            &.avatar-right {
                                top: 1250px!important;
                                right: -70px!important;
                            }
                        }
                        &.one-left {
                            left: -125px;
                            top: -145px;
                            @media( max-width: 1200px ) {
                                left: -190px;
                                top: -120px;
                            }
                            @media( max-width: 820px ) {
                                top: -180px;
                            }
                            @media( max-width: 500px ) {
                                top: -115px;
                                left: -35px;
                                img {
                                    width: 175px;
                                    height: 153px;
                                }
                            }
                            img {
                                &.tablet {
                                    display: none!important;
                                    @media( max-width: 1200px ) {
                                        display: block!important;
                                    }
                                    @media( max-width: 500px ) {
                                        display: none!important;
                                    }
                                }
                                
                                &.mobile {
                                    display: none!important;
                                    @media( max-width: 500px ) {
                                        display: block!important;
                                    }
                                }
                            }
                        }
                        &.two-right {
                            top: 400px;
                            right: -295px;
                            @media( max-width: 1200px ) {
                                right: -115px;
                                top: 415px;
                            }
                            @media( max-width: 500px ) {
                                top: 235px;
                                right: -35px;
                            }
                            img {
                                &.tablet {
                                    display: none!important;
                                    @media( max-width: 1200px ) {
                                        display: block!important;
                                    }
                                    @media( max-width: 500px ) {
                                        display: none!important;
                                    }
                                }
                                
                                &.mobile {
                                    display: none!important;
                                    @media( max-width: 500px ) {
                                        display: block!important;
                                    }
                                }
                            }
                        }
                        &.three-left {
                            top: 700px;
                            left: -260px;
                            @media( max-width: 1200px ) {
                                top: 470px;
                                left: -55px;
                                img {
                                    width: 390px;
                                    height: 350px;
                                }
                            }
                            @media( max-width: 992px ) {
                                left: initial;
                                right: -25px;
                                top: -160px;
                            }
                            @media( max-width: 500px ) {
                                top: -170px;
                                right: -35px;
                                img {
                                    width: 176px;
                                    height: 210px;
                                }
                            }
                            img {
                                &.tablet {
                                    display: none!important;
                                    @media( max-width: 1200px ) {
                                        display: block!important;
                                    }
                                    @media( max-width: 500px ) {
                                        display: none!important;
                                    }
                                }
                                
                                &.mobile {
                                    display: none!important;
                                    @media( max-width: 500px ) {
                                        display: block!important;
                                    }
                                }
                            }
                        }
                        &.four-right {
                            top: 1130px;
                            right: -95px;
                            @media( max-width: 1200px ) {
                                top: 1050px;
                                right: 0!important;
                                img {
                                    width: 60px;
                                    height: 60px;
                                }
                            }
                            @media( max-width: 992px ) {
                                display: none;
                            }
                        }
                        &.arrow {
                            top: 700px;
                            transform: translateX(-50%);
                            @media( max-width: 1200px ) {
                                top: 605px;
                                svg {
                                    width: 22px;
                                    height: 39px;
                                }
                            }
                            @media( max-width: 820px ) {
                                top: 500px;
                            }
                            @media( max-width: 500px ) {
                                top: 300px;
                            }
                            @media( max-width: 400px ) {
                                top: 305px;
                            }
                        }
                        &.arrow-two {
                            top: 1780px;
                            transform: translateX(-50%);
                            @media( max-width: 1200px ) {
                                top: 1460px;
                                svg {
                                    width: 22px;
                                    height: 39px;
                                }
                            }
                            @media( max-width: 870px ) {
                                display: none;
                            }
                        }
                        &.avatar-left {
                            top: 1150px;
                            left: 0px;
                            @media( max-width: 1200px ) {
                                top: 1020px!important;
                                left: 45px!important;
                                .image-wrapper {
                                    img {
                                        width: 125px!important;
                                        height: 125px!important;
                                    }
                                }
                                .ww-box {
                                    top: 100px!important;
                                    left: -120px!important;
                                }
                            }
                            @media( max-width: 1110px ) {
                                transform: scale(.8);
                            }
                            @media( max-width: 992px ) {
                                display: none;
                            }
                            .image-wrapper {
                                img {
                                    width: 168px;
                                    height: 168px;
                                }
                            }
                            .ww-box {
                                position: absolute;
                                top: -25px;
                                left: -215px;
                            }
                        }
                        &.avatar-right {
                            top: 1200px;
                            right: -160px;
                            @media( max-width: 1200px ) {
                                top: 1080px!important;
                                right: -40px!important;
                                transform: scale(1);
                                .image-wrapper {
                                    img {
                                        width: 275px!important;
                                        height: 275px!important;
                                    }
                                }
                                .ww-box {
                                    top: 60px!important;
                                    left: 160px!important;
                                }
                            }
                            @media( max-width: 1110px ) {
                                top: 1050px!important;
                                transform: scale(.8);
                            }
                            @media( max-width: 992px ) {
                                display: none;
                            }
                            .image-wrapper {
                                img {
                                    width: 389px;
                                    height: 389px;
                                }
                            }
                            .ww-box {
                                position: absolute;
                                top: 90px;
                                left: 230px;
                            }
                        }
                    }
                }
            }
        }
    }
    // .border {
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     img {
    //         display: block;
    //         max-width: 1920px;
    //         width: 100%;;
    //     }
    // }
}