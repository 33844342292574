.inspiration {
    background-color: #DE171E;
    position: relative;
    @media( max-width: 992px ) {
        height: 250px;
    }
    .ww-img {
        position: absolute;
        bottom: -240px;
        left: 0;
        @media( max-width: 1700px ) {
            bottom: -300px;
            left: -100px;
        }
        @media( max-width: 1500px ) {
            left: -150px;
        }
        @media( max-width: 1380px ) {
            left: -200px;
        }
        @media( max-width: 1280px ) {
            left: -230px;
        }
    }
    .container {
        .title-wrapper {
            @media( max-width: 400px ) {
                padding-top: 20px;
            }
            * {
                color: #ffd62b;
                font-size: 62.33px;
                padding: 0;
                @media( max-width: 1200px ) {
                    font-size: 45.7px;
                }
                @media( max-width: 500px ) {
                    font-size: 30px;
                }
            }
        }
        .inspiration-items-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 50px -25px 0 -25px;
            @media( max-width: 1200px ) {
                margin: 20px -20px 0 -20px;
            }
            @media( max-width: 500px ) {
                margin-top: 15px;
            }
            @media( max-width: 992px ) {
                display: none;
                &.inspiration-items-wrapper-slider {
                    display: block !important;
                }
            }
            &.inspiration-items-wrapper-slider {
                display: none;
                .item {
                    margin-bottom: 0!important;
                    margin-top: 20px!important;
                }
            }
            .item {
                display: flex;
                align-items: center;
                margin: 0 25px 60px 25px;
                width: 100%;
                max-width: calc(33.3% - 50px);
                background-color: #ffd62b;
                padding: 28px 35px 29px 35px;
                position: relative;
                z-index: 2;
                text-align: center;
                &.small-font {
                    p {
                        font-size: 27px!important;
                        @media( max-width: 1200px ) {
                            font-size: 24px!important;
                        }
                    }
                }
                @media( max-width: 1200px ) {
                    max-width: calc(33.3% - 40px);
                    margin: 0 20px 42px 20px;
                    padding: 23px 27px 21px 27px;
                    &:after {
                        border-left: 14px solid transparent!important;
                        border-bottom: 14px solid #ffd62b!important;
                        top: -14px!important;
                    }
                    p {
                        font-size: 30.79px!important;
                    }
                }
                @media( max-width: 992px ) {
                    max-width: calc(100% - 40px);
                    min-height: 152px;
                }
                @media( max-width: 400px ) {
                    // margin: 0;
                    max-width: 280px;
                    margin: 0 12.5px 42px 12.5px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: -18px;
                    right: 0;
                    width: 0;
                    height: 0;
                    border-left: 19px solid transparent;
                    border-right: 0px solid transparent;
                    border-bottom: 19px solid #ffd62b;
                }
                p {
                    color: #de171d;
                    font-size: 42px;
                    line-height: 44px;
                    font-weight: 900;
                }
            }
        }
    }
}