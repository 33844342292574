.arrow-icon {
    svg {
        animation: arrow 1s infinite;
    }
    @keyframes arrow {
        0% {
            transform: translateY(0);
        }
        99% {
            transform: translateY(35px);
        }
        100% {
            transform: translateY(0);
        }
    }
}