.checkbox-wrapper {
    .checkbox {
        display: flex;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &.error {
            .icon {
                border: 6px solid #de171d !important;
            }
        }
    
        span, a {
            color: #de171d;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 20px;
            text-align: justify;
            @media( max-width: 1200px ) {
                margin-left: 12px;
                font-size: 9.71px;
            }
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
    
        input:checked ~ .icon svg,
        input:checked ~ .icon .check {
            display: block;
        }
        .icon {
            border: 6px solid #fff;
            max-width: 30px;
            min-width: 30px;
            max-height: 30px;
            min-height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media( max-width: 1200px ) {
                max-width: 22px;
                min-width: 22px;
                max-height: 22px;
                min-height: 22px;
                .check {
                    width: 12px!important;
                    height: 12px!important;
                }
            }
            .check {
                display: none;
                background-color: #de171d;
                // width: 8px;
                // height: 8px;
                width: 18px;
                height: 18px;
            }
        }
    }
}