.slider-items {
    background-color: #DE171E;
    padding-bottom: 220px;
    padding-top: 50px;
    outline: none;
    @media( max-width: 1200px ) {
        padding-bottom: 120px;
        padding-top: 60px;
    }
    @media( max-width: 870px ) {
        padding-bottom: 60px;
        padding-top: 10px;
    }
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        outline: none;
        .title-wrapper {
            max-width: 1000px;
            margin-bottom: 120px;
            @media( max-width: 1200px ) {
                margin-bottom: 100px;
            }
            @media( max-width: 870px ) {
                margin-bottom: 50px;
            }
            * {
                color: #ffd62b;
                font-size: 62.33px;
                line-height: 70px;
                padding: 0;
                @media( max-width: 1200px ) {
                    font-size: 45.7px;
                    line-height: 1;
                }
                @media( max-width: 500px ) {
                    line-height: 34px;
                    font-size: 30px;
                }
            }
        }
        .slider-items-wrapper {
            margin: 0 auto;
            text-align: center;
            width: 100%;
            outline: none;
            max-width: 800px;
            .slick-arrow {
                z-index: 10;
                top: 50%;
                height: initial;
                width: initial;
                // @media( max-width: 1250px ) {
                //     height: 50px;
                //     width: 65px;
                //     top: calc(50% - 25px);
                // }
                // @media( max-width: 800px ) {
                //     top: calc(50% - 10px);
                // }
                // @media( max-width: 700px ) {
                //     width: 40px;
                //     height: 40px;
                //     top: 50%;
                // }
                &:before {
                    content: none;
                }
                .arrow {
                    &.prev {
                        // transform: translateY(-50%);
                        // @media( max-width: 1250px ) {
                        //     transform: translateY(-100%);
                        // }
                        // @media( max-width: 1250px ) {
                        //     transform: translateY(-50%);
                        // }
                    }
                    &.next {
                        transform: translateY(-50%);
                        // @media( max-width: 1250px ) {
                        //     transform: translateY(-150%);
                        // }
                        // @media( max-width: 1250px ) {
                        //     transform: translateY(-100%);
                        // }
                    }
                    p {
                        color: #ffd62b;
                        cursor: pointer;
                        line-height: 1;
                        font-size: 62.33px;
                        font-weight: 900;
                        font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
                        // transform: translateY(-25%);
                        padding-bottom: 3px;
                        @media( max-width: 1200px ) {
                            font-size: 45.7px;
                        } 
                        @media( max-width: 500px ) {
                            font-size: 30px;
                        }
                    }
                }
                &.slick-prev {
                    left: 0;
                }
                &.slick-next {
                    right: 0;
                }
            }
            .item {
                display: flex!important;
                justify-content: center;
                align-items: center;
                outline: none;
                height: 100%;
                max-height: 270px;
                @media( max-width: 1024px ) {
                    max-height: 190px;
                }
                @media( max-width: 800px ) {
                    max-height: 160px;
                }
                @media( max-width: 650px ) {
                    max-height: 144px;
                }
                @media( max-width: 450px ) {
                    min-height: 110px;
                }
                img {
                    max-width: 1050px;
                    outline: none;
                    transform: scale(.7);
                    @media( max-width: 1450px ) {
                        // max-width: 900px;
                        transform: scale(.65);
                    }
                    @media( max-width: 1100px ) {
                        // max-width: 800px;
                        transform: scale(.6);
                    }
                    @media( max-width: 1000px ) {
                        // max-width: 800px;
                        transform: scale(.5);
                    }
                    @media( max-width: 800px ) {
                        max-width: 100%;
                        transform: scale(.8);
                        max-height: 200px;
                    }
                    @media( max-width: 650px ) {
                        max-height: 180px;
                    }
                    @media( max-width: 600px ) {
                        max-height: 160px;
                    }
                    @media( max-width: 550px ) {
                        max-width: 350px;
                    }
                    @media( max-width: 500px ) {
                        max-width: 300px;
                    }
                    @media( max-width: 450px ) {
                        max-width: 250px;
                    }
                    @media( max-width: 400px ) {
                        max-width: 220px;
                    }
                }
            }
        }
        .info-wrapper {
            margin-top: 120px;
            margin-bottom: 60px;
            max-width: 1100px;
            text-align: center;
            @media( max-width: 1200px ) {
                margin-top: 100px;
                margin-bottom: 40px;
            }
            @media( max-width: 870px ) {
                margin-top: 50px;
            }
            p {
                font-size: 35px;
                line-height: 55px;
                color: #ffd62b;
                font-weight: 900;
                font-style: italic;
                font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                @media( max-width: 870px ) {
                    font-size: 30px;
                    line-height: 35px;
                }
                @media( max-width: 500px ) {
                    font-size: 22px;
                    line-height: 26px;
                }
                br {
                    display: inline;
                    @media( max-width: 1200px ) {
                        display: none;
                    }
                    &.mobile {
                        display: none!important;
                        @media( max-width: 420px ) {
                            display: block !important;
                        }
                    }
                }
            }
        }
        .btn-wedel-wrapper {
            .wrapper {
                display: flex;
                background-color: #ffffff;
                border-radius: 20px;
                max-width: 700px;
                width: 100%;
                min-height: 170px;
                text-decoration: none;
                @media( max-width: 870px ) {
                    min-height: max-content;
                    padding: 10px;
                }
                @media( max-width: 420px ) {
                    border-radius: 15px;
                }
                .col {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: 50%;
                    width: 100%;
                    &:first-of-type {
                        border-right: 1px solid #024891;
                    }
                    &:last-of-type {
                        padding-left: 30px;
                        border-left: 1px solid #024891;
                        @media( max-width: 500px ) {
                            padding-left: 20px;
                        }
                    }
                    svg {
                        width: 225px;
                        height: 115px;
                        fill: #024891;
                        @media( max-width: 870px ) {
                            width: 200px;
                            height: 100px;
                        }
                        @media( max-width: 500px ) {
                            width: 160px;
                            height: auto;
                        }
                        @media( max-width: 450px ) {
                            width: 145px;
                        }
                        @media( max-width: 420px ) {
                            width: 125px;
                            height: auto;
                            max-height: 50px;
                        }
                    }
                    p {
                        text-transform: uppercase;
                        font-size: 33px;
                        line-height: 35px;
                        color: #024891;
                        font-weight: 900;
                        font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                        @media( max-width: 870px ) {
                            font-size: 28px;
                        }
                        @media( max-width: 500px ) {
                            font-size: 24px;
                            line-height: 30px;
                        }
                        @media( max-width: 420px ) {
                            font-size: 18px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }
}