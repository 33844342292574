.winners {
    background-color: #DE171E;
    padding-top: 40px;
    // min-height: 760px;
    @media( max-width: 1200px ) {
        // min-height: 800px;
    }
    .container {
        .title-wrapper {
            * {
                color: #ffd62b;
                font-size: 62.33px;
                padding: 0;
                @media( max-width: 1200px ) {
                    font-size: 45.7px;
                }
                @media( max-width: 500px ) {
                    font-size: 30px;
                }
            }
        }
        .weeks-wrapper {
            max-width: 610px;
            width: 100%;
            margin: 30px auto 0 auto;
            @media( max-width: 1200px ) {
                margin-top: 20px;
                max-width: 420px;
            }
            @media( max-width: 570px ) {
                max-width: 320px;
            }
            @media( max-width: 420px ) {
                max-width: 250px;
            }
            .slick-arrow {
                width: 62px;
                height: 62px;
                top: 50%;
                transform: translate(0);
                @media( max-width: 1200px ) {
                    width: 52px;
                    height: 52px;
                }
                @media( max-width: 570px ) {
                    width: 39px;
                    height: 39px;
                }
                &:before {
                    content: none;
                }
                &.slick-next {
                    transform: rotate(180deg) translateY(50%);
                    right: -67px;
                    @media( max-width: 570px ) {
                        right: -46px;
                    }
                    .arrow-slider-triangle {
                        .play {
                            right: 3px;
                            position: relative;
                        }
                    }
                }
                &.slick-prev {
                    left: -67px;
                    transform: translateY(-50%);
                    @media( max-width: 570px ) {
                        left: -46px;
                    }
                    .arrow-slider-triangle {
                        .play {
                            left: -3px;
                            position: relative;
                        }
                    }
                }
            }
            .slick-dots {
                bottom: -40px;
                li {
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    border: 2px solid #ffd62b;
                    @media( max-width: 570px ) {
                        width: 12px;
                        height: 12px;
                    }
                    &.slick-active {
                        background-color: #ffd62b;
                    }
                }
            }
            .arrow-slider-triangle {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 62px;
                height: 62px;
                border-radius: 50%;
                border: 3px solid #ffd62b;
                cursor: pointer;
                @media( max-width: 1200px ) {
                    width: 52px;
                    height: 52px;
                }
                @media( max-width: 570px ) {
                    width: 39px;
                    height: 39px;
                }
                .play {
                    width: 0; 
                    height: 0; 
                    border-top: 11px solid transparent;
                    border-bottom: 11px solid transparent; 
                    border-right:16px solid #ffd62b; 
                    @media( max-width: 570px ) {
                        border-top: 7px solid transparent;
                        border-bottom: 7px solid transparent; 
                        border-right:10px solid #ffd62b;  
                    }
                }
            }
            .week {
                display: flex !important;
                justify-content: center;
                &.active {
                    .week-number {
                        background-color: #ffd62b;
                        color: #de171d;
                        &:before {
                            content: none;
                        }
                    }
                }
                .week-number {
                    font-size: 20.42px;
                    font-weight: 900;
                    color: #ffd62b;
                    border: 3px solid #ffd62b;
                    width: max-content;
                    padding: 15px 28px 14px 28px;
                    font-style: italic;
                    border-radius: 56.66px;
                    transition: .3s;
                    cursor: pointer;
                    position: relative;
                    white-space: nowrap;
                    @media( max-width: 1200px ) {
                        font-size: 15px;
                        padding: 14px 18px 13px 23px;
                    }
                    @media( max-width: 570px ) {
                        font-size: 11px;
                        padding: 9px 14px 8px 18px;
                    }
                    &:hover {
                        color: #de171d;
                        background-color: #ffd62b;
                        // &:before {
                        //     transform: translateY(0);
                        // }
                    }
                    // &:before {
                    //     content: "";
                    //     position: absolute;
                    //     top: 0;
                    //     left: 0;
                    //     height: 100%;
                    //     width: 100%;
                    //     border-radius: 56.66px;
                    //     transition: .3s;
                    //     z-index: -1;
                    //     background-color: #ffd62b;
                    //     transform: translateY(110%);
                    // }
                }
            }
        }
        .rewards-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 65px;
            .reward {
                font-size: 20.42px;
                font-weight: 900;
                color: #ffd62b;
                border: 3px solid #ffd62b;
                width: max-content;
                padding: 15px 28px 14px 28px;
                font-style: italic;
                border-radius: 56.66px;
                transition: .3s;
                cursor: pointer;
                position: relative;
                margin: 0 10px;
                white-space: nowrap;
                @media( max-width: 1200px ) {
                    font-size: 15px;
                    padding: 14px 18px 13px 23px;
                }
                @media( max-width: 570px ) {
                    font-size: 11px;
                    padding: 11px 14px 10px 18px;
                    margin: 0 5px;
                }
                @media( max-width: 410px ) {
                    padding: 9px 12px 8px 16px;
                }
                &:hover {
                    color: #de171d;
                    background-color: #ffd62b;
                    // &:before {
                    //     transform: translateY(0);
                    // }
                }
                &.active {
                    background-color: #ffd62b;
                    color: #de171d;
                    // &:before {
                    //     content: none;
                    // }
                }
                // &:before {
                //     content: "";
                //     position: absolute;
                //     top: 0;
                //     left: 0;
                //     height: 100%;
                //     width: 100%;
                //     border-radius: 56.66px;
                //     transition: .3s;
                //     z-index: -1;
                //     background-color: #ffd62b;
                //     transform: translateY(110%);
                // }
            }
        }
        .winners-list {
            margin-top: 55px;
            padding-bottom: 30px;
            ::-webkit-scrollbar {
                width: 40px;
            }

            // ::-webkit-scrollbar-track {
            //     background: #ffffff; 
            //     border: 4px solid transparent;
            //     // background-clip: content-box;
            // }
            // ::-webkit-scrollbar-thumb {
            //     background: #000000;
            //     border-radius: 10px;
            // }
            ::-webkit-scrollbar-track {
                background: #ffffff;
                border-left: 18px solid #e2171e;
                border-right: 18px solid #e2171e;
            }
        
            ::-webkit-scrollbar-thumb {
                background: #ffffff;
                border-left: 3px solid #e2171e;
                border-right: 3px solid #e2171e;
                border-radius: 30px;
                border-top: 4px solid #e2171e;
                border-bottom: 4px solid #e2171e;
                // box-shadow: inset 0 0 10px 10px red;
            }
              
            ::selection {
                color: #ffffff;
                background: #000000;
            }
            .scroll-list {
                max-height: 460px;
                overflow-y: scroll;
                @media( max-width: 1200px ) {
                    max-height: 360px;
                }
                .row {
                    border-bottom: 3px dotted #ffffff;
                    padding-bottom: 30px;
                    margin-bottom: 30px;
                    max-width: 1180px;
                    @media( max-width: 1200px ) {
                        // padding-bottom: 23px;
                        // margin-bottom: 27px;
                        // max-width: 900px;
                        padding-bottom: 10px;
                        margin-bottom: 15px;
                        max-width: 900px;
                    }
                    @media( max-width: 500px ) {
                        max-width: 100%;
                        margin-right: 25px;
                    }
                    p {
                        color: #ffffff;
                        font-weight: 900;
                        font-size: 42px;
                        text-transform: uppercase;
                        @media( max-width: 1200px ) {
                            // font-size: 30.79px;
                            font-size: 25px;
                        }
                        @media( max-width: 500px ) {
                            font-size: 16px;
                        }
                    }
                }
            }
            .items {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -25px 0 -25px;
                @media( max-width: 1200px ) {
                    margin: 0 -20px 0 -20px;
                }
                @media( max-width: 992px ) {
                    display: none;
                    &.items-slider {
                        display: block !important;
                    }
                }
                &.items-slider {
                    display: none;
                }
                .item {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin: 0 25px 50px 25px;
                    width: 100%;
                    max-width: calc(33.3% - 50px);
                    background-color: #ffffff;
                    padding: 28px 35px 29px 35px;
                    position: relative;
                    @media( max-width: 1200px ) {
                        max-width: calc(33.3% - 40px);
                        margin: 0 20px 42px 20px;
                        padding: 23px 27px 21px 27px;
                        &:after {
                            border-left: 14px solid transparent!important;
                            border-bottom: 14px solid #ffffff!important;
                            top: -14px!important;
                        }
                        p {
                            &.place {
                                // font-size: 61.59px!important;
                                font-size: 24.93px!important;
                            }
                            &.ww {
                                font-size: 24.93px!important;
                            }
                            &.author {
                                font-size: 12.12px!important;
                            }
                        }
                    }
                    @media( max-width: 992px ) {
                        display: flex!important;
                        max-width: calc(100% - 40px);
                        margin-top: 14px;
                        min-height: 120px;
                    }
                    .row {
                        display: flex;
                        align-items: flex-start;
                        width: 100%;
                        overflow: hidden;
                        &.small-font {
                            .place, .ww {
                                font-size: 26px!important;
                                @media( max-width: 1400px ) {
                                    font-size: 24px!important;
                                }
                                @media( max-width: 1250px ) {
                                    font-size: 22px!important;
                                }
                                @media( max-width: 1200px ) {
                                    font-size: 19px!important;
                                }
                            }
                        }
                        &.big-small-font {
                            .place, .ww {
                                font-size: 21px!important;
                                @media( max-width: 1400px ) {
                                    font-size: 19px!important;
                                }
                                
                                @media( max-width: 1300px ) {
                                    font-size: 17px!important;
                                }
                                @media( max-width: 1200px ) {
                                    font-size: 15px!important;
                                }
                            }
                        }
                    }
                    p {
                        color: #de171d;
                        font-weight: 900;
                        line-height: 1;
                        text-transform: uppercase;
                        font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                        &.place {
                            // font-size: 84px;
                            // line-height: .75;
                            // padding-right: 50px;
                            // letter-spacing: -5px;
                            font-size: 34px;
                            position: relative;
                            padding-right: 10px;
                            // &:after {
                            //     content: "";
                            //     position: absolute;
                            //     top: 0;
                            //     right: 30px;
                            //     height: 95px;
                            //     width: 1px;
                            //     background-color: #de171d;
                            //     transform: rotate(30deg);
                            // }
                        }
                        &.ww {
                            font-size: 34px;
                        }
                        &.author {
                            font-size: 16.5px;
                            text-align: right;
                            width: 100%;
                            margin-top: 15px;
                        }
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        top: -18px;
                        right: 0;
                        width: 0;
                        height: 0;
                        border-left: 19px solid transparent;
                        border-right: 0px solid transparent;
                        border-bottom: 19px solid #ffffff;
                    }
                }
            }
        }
    }
}