header {
    .container {
        position: relative;
        @media( max-width: 1200px ) {
            max-width: 1100px;
        }
        .wrapper {
            position: absolute;
            top: 40px;
            left: 0;
            width: 100%;
            @media( max-width: 1200px ) {
                top: 30px;
            }
            @media( max-width: 992px ) {
                top: 0;
            }
            nav {
                &.desktop-nav {
                    @media( max-width: 992px ) {
                        display: none;
                    }
                }
                &.mobile-nav {
                    display: none;
                    @media( max-width: 992px ) {
                        padding: 10px 30px;
                        height: 78px;
                        display: flex;
                        align-items: center;
                    }
                }
                .logo {
                    img {
                        width: 218px;
                        height: 115px;
                        @media( max-width: 992px ) {
                            width: 98px;
                            height: 52px;
                            position: relative;
                            left: -15px;
                        }
                    }
                }
                &.mobile-nav {
                    ul {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        align-items: center;
                        top: 78px;
                        left: 50%;
                        width: 100%;
                        background-color: #FFCF23;
                        z-index: 100;
                        transform: translateX(-50%) translateY(-150%);
                        transition: .5s;
                        padding: 10px 30px;
                        &.is-active {
                            transform: translateX(-50%) translateY(0); 
                            padding: 0;
                        }
                        li {
                            width: 100%;
                            text-align: center;
                            &:first-of-type {
                                a {
                                    border-top: 1px solid #de171d;
                                }
                            }
                            a {
                                color: #de171d;
                                border-bottom: 1px solid #de171d;
                                width: 100%;
                                padding: 30px 10px;
                                font-weight: 900;
                                font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                            }
                        }
                    }
                }
                .mobile-hamburger {
                    width: 100%;
                    justify-content: flex-end;
                    align-items: center;
                    display: flex;
                    height: 100%;
                    .hamburger-wrap {
                        position: relative;
                        z-index: 10;
                        transition: .3s ease-in-out all;                
                    .hamburger .line{
                        width: 36px;
                        height: 5px;
                        background-color: #de171d;
                        display: block;
                        margin-bottom: 1px;
                        -webkit-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                      }
                      
                      .hamburger:hover{
                        cursor: pointer;
                      }
                
                      .hamburger{
                        -webkit-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                      }
                      
                      .hamburger.is-active{
                        animation: smallbig 0.6s forwards;
                        position: relative;
                        top: 4px;
                      }
                      
                      @keyframes smallbig{
                        0%, 100%{
                          -webkit-transform: scale(1);
                          -ms-transform: scale(1);
                          -o-transform: scale(1);
                          transform: scale(1);
                        }
                      
                        50%{
                          -webkit-transform: scale(0);
                          -ms-transform: scale(0);
                          -o-transform: scale(0);
                          transform: scale(0);
                        }
                      }
                      
                      .hamburger.is-active .line:nth-child(1),
                      .hamburger.is-active .line:nth-child(2),
                      .hamburger.is-active .line:nth-child(3),
                      .hamburger.is-active .line:nth-child(4){
                        -webkit-transition-delay: 0.2s;
                        -o-transition-delay: 0.2s;
                        transition-delay: 0.2s;
                      }
                      
                      .hamburger.is-active .line:nth-child(2),
                      .hamburger.is-active .line:nth-child(4){
                        opacity: 0;
                      }
                      
                      .hamburger.is-active .line:nth-child(1){
                        -webkit-transform: translateY(6px) rotate(45deg);
                        -ms-transform: translateY(6px) rotate(45deg);
                        -o-transform: translateY(6px) rotate(45deg);
                        transform: translateY(6px) rotate(45deg);
                      }
                      
                      .hamburger.is-active .line:nth-child(3){
                        -webkit-transform: translateY(-7px) rotate(-45deg);
                        -ms-transform: translateY(-7px) rotate(-45deg);
                        -o-transform: translateY(-7px) rotate(-45deg);
                        transform: translatey(-7px) rotate(-45deg);
                      }
                    }
                }
            }
            ul {
                display: flex;
                justify-content: space-between;
                margin: 0;
                padding: 0;
                li {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    list-style: none;
                    position: relative;
                    width: 100%;
                    &.video {
                        a {
                            padding-top: 0;
                            padding-bottom: 0;
                            &:before {
                                content: none;
                            }
                            &:hover {
                                transform: scale(.9);
                            }
                            img {
                                @media( max-width: 1200px ) {
                                    width: 134px;
                                    height: 101px;
                                }
                            }
                        }
                    }
                    &.logo {
                        img {
                            @media( max-width: 1200px ) {
                                width: 186px;
                                height: 98px;
                            }
                        }
                    }
                    @media( min-width: 993px ) {
                        &:hover {
                            a {
                                transform: rotate(-15deg);
                            }
                            svg {
                                opacity: 1;
                                transform: translate(10%, 3%) scale(1);
                                @media( max-width: 1350px ) {
                                    transform: translate(8%, 3%) scale(1);
                                }
                                @media( max-width: 1300px ) {
                                    transform: translate(6%, 3%) scale(1);
                                }
                                @media( max-width: 1250px ) {
                                    transform: translate(4%, 3%) scale(1);
                                }
                                @media( max-width: 1200px ) {
                                    transform: translate(-4%, -5%) scale(1) !important;
                                }
                                @media( max-width: 1050px ) {
                                    transform: translate(-8%, -5%) scale(1) !important;
                                }
                            }
                            &:nth-of-type(5),
                            &:nth-of-type(6),
                            &:nth-of-type(7) {
                                a {
                                    font-size: 19px;
                                    @media( max-width: 1400px ) {
                                        font-size: 18px;
                                    }
                                }
                                svg {
                                    @media( max-width: 1400px ) {
                                        transform: translate(10%, 3%) scale(1);
                                    }
                                    @media( max-width: 1350px ) {
                                        transform: translate(8%, 3%) scale(1);
                                    }
                                    @media( max-width: 1300px ) {
                                        transform: translate(6%, 3%) scale(1);
                                    }
                                    @media( max-width: 1250px ) {
                                        transform: translate(4%, 3%) scale(1);
                                    }
                                    @media( max-width: 1050px ) {
                                        transform: translate(-6%, -5%) scale(1) !important;
                                    }
                                }
                            }
                            // &:nth-of-type(2) {
                            //     svg {
                            //         transform: translate(10%, 3%) scale(1.1);
                            //     }
                            // }
                            // &:nth-of-type(5) {
                            //     svg {
                            //         transform: translate(10%, 3%) scale(1.25);
                            //     }
                            // }
                            // &:nth-of-type(6) {
                            //     svg {
                            //         transform: translate(10%, 3%) scale(1.2);
                            //     }
                            // }
                            // &:nth-of-type(7) {
                            //     svg {
                            //         transform: translate(10%, 3%) scale(1.25);
                            //     }
                            // }
                        }
                    }
                    svg {
                        // transform: translate(-100%, 100%);
                        transform: translate(10%, 0%) scale(.9);
                        opacity: 0;
                        // transform: translate();
                        transition: .3s;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        position: relative;
                        color: #de171d;
                        font-weight: 900;
                        font-size: 22px;
                        text-decoration: none;
                        text-transform: uppercase;
                        font-style: italic;
                        transition: .3s;
                        overflow: hidden;
                        padding: 10px;
                        cursor: pointer;
                        z-index: 1;
                        font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                        @media( max-width: 1200px ) {
                            font-size: 18px;
                        }
                        @media( max-width: 1150px ) {
                            font-size: 16px;
                        }
                        // &:before {
                        //     position: absolute;
                        //     bottom: -10px;
                        //     left: 0;
                        //     content: "";
                        //     border: 8px solid #de171d;
                        //     width: 100%;
                        //     transform: translateX(-200%) skewX(-20deg);
                        //     transition: .3s;
                        // }
                        // &:hover {
                        //     &:before {
                        //         transform: translateX(0) skewX(0);
                        //     }
                        // }
                    }
                }
            }
        }
    }
}