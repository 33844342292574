.btn-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    &:before, &:after {
        content: "";
        position: absolute;
        background-color: #e2171e;
        height: 2px;
        width: 100%;
        max-width: calc(50% - 475px / 2 - 30px);
        left: 0;
        top: 50%;
        @media( max-width: 1200px ) {
            max-width: calc(50% - 341px / 2 - 20px);
        }
        @media( max-width: 400px ) {
            content: none;
        }
    }
    &:after {
        right: 0;
        left: initial;
    }
    .click-wrapper {
        display: flex;
        // padding: 0 30px;
        z-index: 10;
        background-color: #ffd62b;
        position: relative;
        margin: 0 30px;
        border-radius: 50px;
        overflow: hidden;
        &:hover {
            input {
                color: #ffd62b;
            }
            &:before {
                transform: translateY(0);
            }
            .icon-svg {
                svg {
                    path {
                        fill: #ffd62b;
                    }
                }
            }
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #e2171e;
            border-radius: 50px;
            transform: translateY(-100%);
            transition: .3s;
            z-index: -1;
        }
        @media( max-width: 1200px ) {
            margin: 0 20px;
        }
        @media( max-width: 700px ) {
            margin: 0;
        }
        
    }
    input {
        display: flex;
        align-items: center;
        border: 16px solid #e2171e;
        color: #e2171e;
        font-size: 30px;
        font-weight: 900;
        border-radius: 50px;
        background-color: transparent;
        cursor: pointer;
        padding: 13px 70px 13px 21px;
        font-style: italic;
        line-height: 1;
        min-height: 97px;
        transition: .3s;
        @media( max-width: 1200px ) {
            font-size: 22px;
            border: 11px solid #e2171e;
            padding: 15px 45px 15px 16px;
            min-height: 70px;
        }
        @media( max-width: 400px ) {
            font-size: 22px;
            min-height: 70px;
        }
        @media( max-width: 390px ) {
            font-size: 20px;
            padding: 12px 40px 12px 14px;
        }
    }
    .icon-svg {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
        z-index: -1;
        svg {
            path {
                transition: .3s;
            }
        }
        @media( max-width: 1200px ) {
            right: 20px;
        }
    }
}