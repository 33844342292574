footer {
    background-color: #ffd62b;
    position: relative;
    padding-bottom: 80px;
    @media( max-width: 1200px ) {
        padding-bottom: 50px;
    }
    @media(max-width: 680px ) {
        padding-bottom: 25px;
    }
    .container {
        position: relative;
        .ww-img {
            position: absolute;
            top: -245px;
            right: 80px;
            @media( max-width: 1200px ) {
                right: 40px;
                top: -170px;
            }
            @media( max-width: 680px ) {
                right: 0;
                top: -135px;
            }
            @media( max-width: 450px ) {
                top: -100px;
            }
            &.tablet {
                display: none!important;
                @media( max-width: 1200px ) {
                    display: block!important;
                }
                @media( max-width: 680px ) {
                    display: none!important;
                }
            }
            
            &.mobile {
                display: none!important;
                @media( max-width: 680px ) {
                    display: block!important;
                }
            }
        }
        .wrapper {
            .row {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                &.row-links-info {
                    @media( max-width: 680px ) {
                        flex-direction: column;
                        .socials {
                            display: flex!important;
                        }
                    }
                }
                .col {
                    max-width: 50%;
                    width: 100%;
                    .logo {
                        svg {
                            fill: #0F4C91;
                            width: 308px;
                            // height: 150px;
                            @media( max-width: 1200px ) {
                                width: 225px;
                            }
                            @media( max-width: 680px ) {
                                padding-top: 90px;
                            }
                            @media( max-width: 500px ) {
                                width: 170px;
                            }
                        }
                        img {
                            width: 308px;
                            height: 150px;
                        }
                        // @media( max-width: 1200px ) {
                        //     img {
                        //         width: 200px;
                        //         height: 110px;
                        //     }
                        // }
                        // @media( max-width: 680px ) {
                        //     padding-top: 90px;
                        // }
                    }
                    // .socials {
                    //     display: flex;
                    //     justify-content: flex-end;
                    //     &.socials-not-mobile {
                    //         @media( max-width: 680px ) {
                    //             display: none;
                    //         }
                    //     }
                    //     a {
                    //         width: 61px;
                    //         height: 61px;
                    //         margin: 0 15px;
                    //         @media( max-width: 1200px ) {
                    //             width: 45px;
                    //             height: 45px;
                    //             margin: 0 11px;
                    //         }
                    //         &:last-of-type {
                    //             margin-right: 0;
                    //         }
                    //         &:hover {
                    //             svg {
                    //                 fill: #034992;
                    //                 transform: scale(1.1);
                    //             }
                    //         }
                    //         svg {
                    //             transition: .3s ease-in-out;
                    //             fill: #e2171e;
                    //         }
                    //     }
                    // }
                }
                .reg {
                    padding-top: 8px;
                    @media( max-width: 500px ) {
                        padding-top: 13px;
                    }
                    a {
                        display: flex;
                        align-items: center;
                        font-size: 39.42px;
                        font-weight: 900;
                        color: #de171d;
                        text-decoration: none;
                        line-height: 1;
                        transition: .3s;
                        font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                        &:hover {
                            transform: translateX(5px);
                            svg {
                                transform: scale(1.1);
                            }
                        }
                        @media( max-width: 1200px ) {
                            font-size: 28.82px;
                        }
                        @media( max-width: 500px ) {
                            // font-size: 24.37px;
                            font-size: 22px;
                        }
                        svg {
                            margin-right: 18px;
                            transition: .3s;
                            @media( max-width: 1200px ) {
                                margin-right: 13px;
                            }
                            @media( max-width: 1200px ) {
                                width: 16px;
                                height: 20px;
                            }
                        }
                    }
                }
                .links {
                    display: flex;
                    align-items: flex-end;
                    font-size: 19.3px;
                    min-width: 500px;
                    @media( max-width: 1200px ) {
                        font-size: 14.11px;
                        min-width: 400px;
                    }
                    @media( max-width: 680px ) {
                        // justify-content: center;
                        width: 100%;
                        margin-top: 20px;
                        min-width: initial;
                    }
                    @media( max-width: 500px ) {
                        margin-top: 10px;
                    }
                    // @media( max-width: 390px ) {
                    //     flex-direction: column;
                    //     align-items: center;
                    // }
                    a {
                        color: #e2171e;
                        text-decoration: none;
                        transition: .3s;
                        @media( max-width: 390px ) {
                            font-size: 13px;
                        }
                        @media( max-width: 370px ) {
                            font-size: 12px;
                        }
                        // &:first-of-type {
                        //     margin-bottom: 10px;
                        // }
                        &:hover {
                            text-decoration: underline;
                            text-shadow: 1px 0 0 currentColor;
                        }
                    }
                    p {
                        color: #e2171e;
                        margin: 0 28px;
                        @media( max-width: 1200px ) {
                            margin: 0 22px;
                        }
                        @media( max-width: 680px ) {
                            margin: 0 10px;
                        }
                        // @media( max-width: 390px ) {
                        //     display: none;
                        // }
                    }
                }
                .info {
                    font-size: 19.3px;
                    color: #e2171e;
                    text-align: right;
                    @media( max-width: 1200px ) {
                        font-size: 14.44px;
                    }
                    @media( max-width: 680px ) {
                        width: 100%;
                        text-align: left;
                        padding-top: 20px;
                        border-top: 1px solid #e2171e;
                        margin-top: 20px;
                    }
                    @media( max-width: 450px ) {
                        font-size: 13px;
                        line-height: 19px;
                    }
                    @media( max-width: 390px ) {
                        font-size: 12px;
                    }
                }
            }
        }
        .socials {
            display: flex;
            justify-content: flex-end;
            &.socials-not-mobile {
                @media( max-width: 680px ) {
                    display: none;
                }
            }
            @media( max-width: 680px ) {
                justify-content: space-between;
                width: 100%;
                margin-top: 20px;
            }
            a {
                width: 61px;
                height: 61px;
                margin: 0 15px;
                @media( max-width: 1200px ) {
                    width: 45px;
                    height: 45px;
                    margin: 0 11px;
                }
                @media( max-width: 680px ) {
                    &:first-of-type {
                        margin-left: 0;
                    }
                    width: 58px;
                    height: 58px;
                }
                &:last-of-type {
                    margin-right: 0;
                }
                &:hover {
                    svg {
                        fill: #034992;
                        transform: scale(1.1);
                    }
                }
                svg {
                    transition: .3s ease-in-out;
                    fill: #e2171e;
                    @media( max-width: 680px ) {
                        width: 58px;
                        height: 58px;
                    }
                }
            }
        }
    }
}