.title-wrapper {
    text-align: center;
    position: relative;
    border-top: 2px solid #de171d;
    border-bottom: 2px solid #de171d;
    &.inborder-text {
        p {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 0 30px;
            @media( max-width: 870px ) {
                display: none;
            }
        }
    }
    &.without-border {
        border: none;
    }
    &.bottom-right-text {
        p {
            text-align: right;
            font-size: 15.4px;
            padding-bottom: 10px;
            @media( max-width: 1200px ) {
                font-size: 11.27px;
                padding-bottom: 7px;
            }
            @media( max-width: 870px ) {
                display: none;
            }
        }
        h2 {
            padding-bottom: 0;
            @media( max-width: 870px ) {
                // padding: 15px 0 23px 0;
                padding: 23px 0 23px 0;
            }
        }
    }
    h2 {
        font-size: 40px;
        font-weight: 900;
        color: #de171d;
        // padding: 20px 0 28px 0;
        padding: 28px 0 28px 0;
        font-style: italic;
        font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
        @media( max-width: 1200px ) {
            font-size: 29.28px;
            // padding: 15px 0 23px 0;
            padding: 23px 0 23px 0;
        }
        @media( max-width: 1200px ) {
            font-size: 30px;
        }
    }
    p {
        font-size: 24px;
        font-weight: 700;
        color: #de171d;
        background-color: #ffd62b;
        font-style: italic;
        @media( max-width: 1200px ) {
            font-size: 17.57px;
        }
    }
}