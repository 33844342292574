@font-face {
    font-family: 'Arial Black';
    src: local('Arial Black'),
        url('./../../fonts/ArialBlack.woff2') format('woff2'),
        url('./../../fonts/ArialBlack.woff') format('woff'),
        url('./../../fonts/ArialBlack.ttf') format('truetype');
        font-weight: 900;
}

body, html {
    margin: 0;
    padding: 0;
    // max-width: 1920px;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
}

.container {
    max-width: 1400px;
    padding: 0 50px;
    margin: 0 auto;
    @media( max-width: 1200px ) {
        max-width: 1010px;
        padding: 0 30px;
    }
    @media( max-width: 400px ) {
        max-width: 400px;
        padding: 0 25px;
    }
}

section {
    max-width: 100%;
    width: 100%;
}

.layout {
    position: relative;
    max-width: 100%;
    width: 100%;
    background-color: #ffcf23;
    @media( max-width: 992px ) {
        .bg-dark {
            padding-top: 78px;
            filter: brightness(50%);
            width: 100%;
            height: 100%;
        }
    }
    .sidebar-img {
        position: absolute;
        top: 70px;
        left: 60px;
        @media( max-width: 1600px ) {
            top: 200px;
        }
        @media( max-width: 1250px ) {
            display: none;
        }
    }
}

h1,h2,h3,h4,h5,h6,p,span {
    margin: 0;
}

.desktop {
    @media( max-width: 1200px ) {
        display: none!important;
    }
}

.tablet {
    display: none!important;
    @media( max-width: 1200px ) {
        display: block!important;
    }
    @media( max-width: 400px ) {
        display: none!important;
    }
}

.mobile {
    display: none!important;
    @media( max-width: 400px ) {
        display: block!important;
    }
}

.animation {
    animation: animationItems 1s ease 0s 1 normal forwards;
}

@keyframes animationItems {
    10% {
        transform: rotate(0);
    }
    40% {
      transform: rotate(-10deg);
    }
    80% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(0);
    }
}

.error-text {
    margin-top: 5px;
    color: #de171d;
    font-size: 11px;
    font-weight: bold;
}

.welcome {
    min-height: 100vh;
    background-color: #fecf22;
}