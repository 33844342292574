.rules {
    padding: 50px 0 70px 0;
    background-color: #ffd62b;
    .container {
        .title-wrapper {
            margin-bottom: 75px;
            @media( max-width: 1200px ) {
                margin-bottom: 50px;
            }
            @media( max-width: 870px ) {
                margin-bottom: 30px;
            }
        }
        .mobile-title {
            display: none;
            @media( max-width: 870px ) {
                display: block;
                font-size: 18px;
                color: #de171d;
                margin-bottom: 25px;
                text-align: center;
            }
        }
        .steps-wrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 70px;
            @media( max-width: 1200px ) {
                margin-bottom: 50px;
            }
            @media( max-width: 870px ) {
                flex-direction: column;
            }
            .step {
                display: flex;
                align-items: center;
                max-width: calc(33.3% - 30px);
                width: 100%;
                margin: 0 15px;
                @media( max-width: 1200px ) {
                    margin: 0 10px;
                }
                @media( max-width: 870px ) {
                    flex-direction: column;
                    max-width: 100%!important;
                    margin: 12px 0 0 0;
                    &:first-of-type {
                        margin-top: 0;
                    }
                }
                &:first-of-type {
                    margin-left: 0;
                }
                &:nth-of-type(2) {
                    max-width: 33.3%;
                }
                &:last-of-type {
                    margin-right: 0;
                }
                .number {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 99px;
                    max-width: 99px;
                    height: 99px;
                    border-radius: 50%;
                    color: #de171d;
                    font-size: 31.43px;
                    border: 17px solid #de171d;
                    margin-right: 24px;
                    @media( max-width: 1200px ) {
                        min-width: 85px;
                        max-width: 85px;
                        height: 85px;
                        font-size: 23px;
                        margin-right: 17px;
                        border: 12px solid #de171d;
                    }
                }
                .text {
                    @media( max-width: 870px ) {
                        max-width: 350px;
                        text-align: center;
                        margin-top: 12px;
                    }
                    p {
                        color: #de171d;
                        font-size: 22px;
                        @media( max-width: 1200px ) {
                            font-size: 16px;
                        }
                    }
                    a {
                        color: #de171d;
                    }
                }
            }
        }
        .text-wrapper {
            display: flex;
            justify-content: center;
            // margin-bottom: 70px;
            * {
                max-width: 910px;
                @media( max-width: 1200px ) {
                    max-width: 670px;
                }
            }
            h3 {
                color: #de171d;
                font-size: 26px;
                font-weight: 700;
                text-align: center;
                font-style: italic;
                @media( max-width: 1200px ) {
                    font-size: 19px;
                }
                @media( max-width: 500px ) {
                    font-size: 16px;
                }
            }
        }
    }
}