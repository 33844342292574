.form {
    background-color: #ffd62b;
    position: relative;
    .container {
        @media(max-width: 400px ) {
            padding-bottom: 50px;
        }
        .title-wrapper {
            h2 {
                padding: 70px 0;
                line-height: 42px;
                @media( max-width: 1200px ) {
                    padding: 50px 0;
                    line-height: 30px;
                }
                @media( max-width: 800px ) {
                    padding: 23px 0;
                }
            }
            p {
                font-size: 20px;
                margin-top: 15px;
                margin-bottom: 0;
                line-height: 1;
                @media( max-width: 1200px ) {
                    font-size: 16px;
                }
            }
        }
        .header-wrapper {
            text-align: center;
            padding: 40px 0 35px 0;
            @media( max-width: 1200px ) {
                padding: 30px 0 35px 0;
                h2 {
                    font-size: 43.59px!important;
                }
            }
            h2 {
                font-size: 59.55px;
                color: #de171d;
                font-style: italic;
                font-weight: 900;
                font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                @media( max-width: 500px ) {
                    font-size: 56px;
                }
            }
        }
        form {
            display: flex;
            flex-direction: column;
            .row {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -20px;
                @media( max-width: 1200px ) {
                    margin: 0 -15px;
                    &:nth-of-type(3) {
                        margin-top: 20px!important;
                    }
                }
                @media( max-width: 870px ) {
                    margin: 0;
                }
                &:nth-of-type(3) {
                    margin: 35px 0 0 0;
                }
            }
            .input-wrapper, .checkbox-wrapper {
                max-width: calc(33.3% - 40px);
                width: 100%;
                margin: 0 20px 20px 20px;
                @media( max-width: 1200px ) {
                    max-width: calc(33.3% - 30px);
                    margin: 0 15px 15px 15px;
                }
                @media( max-width: 870px ) {
                    max-width: 100%;
                    margin: 0 0 20px 0;
                }
            }
            .checkbox-wrapper {
                margin-bottom: 0;
                @media( max-width: 870px ) {
                    margin-bottom: 20px;
                }
            }
        }
    }
    .transition-bg {
        display: block;
    }
    .ww-img {
        position: absolute;
        bottom: 30px;
        right: 75px;
        @media( max-width: 1400px ) {
            right: 0;
        }
    }
}